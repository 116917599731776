<template>
    <cities-component/>
</template>

<script>
    import CitiesComponent from "@/components/admin/catalogs/CitiesComponent";
    
    export default {
        name: "Cities",
        title: "Ciudades | Turismo BC",
        components: { CitiesComponent },
    }
</script>

<style scoped>

</style>